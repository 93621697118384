import * as React from "react"
import Header from './Header';
import './index.scss';
import Tests from "./Tests";
import Footer from "./Footer";
import Blogs from "./Blogs";
import Services from "./Services";
import Benefits from "./Benefits";
import SEO from "../SEO";
import {Blog} from "../../interfaces/Blog";
import {LocaleType} from "../../interfaces/Locale";
import {useEffect} from "react";
import {navigate} from "gatsby";
import {supported_languages} from '../../helpers/values.json';
import GetInTouch from "./GetInTouch";
import Floating from "../Floating";
// markup
const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en";
    }
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
    return lang;
};
const IndexPage = ({pageContext:{blogs,locale,lang,url}}:{pageContext:{blogs:Blog[],locale:LocaleType,lang:string, url:string}}) => {
    useEffect(()=>{
        const urlLang = getRedirectLanguage();
        if(urlLang!==lang&&supported_languages.includes(urlLang)) navigate(`/${urlLang}/`, {replace: true});
    },[])
    return (
    <main>
        <Floating/>
      <SEO blog={false}/>
        <Header locale={locale} />
        <GetInTouch location={url}/>
        <Tests locale={locale}/>
        <Services locale={locale} />
        <Benefits locale={locale} />
        <Blogs blogs={blogs} locale={locale} lang={lang}/>
      <Footer locale={locale}/>
    </main>
  )
}

export default IndexPage